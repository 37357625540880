import goal_logo from './Goal-Solutions-Logo-low-res.png'
import './App.less'
import React, { useEffect, useState } from 'react';
import './index.css';
import { Button, Card,  Modal, Spin, Layout, Anchor } from 'antd';
import TreeComponent from './components/TreeComponent';

const { Header, Sider } = Layout;

const App = () => { 
  const { Link } = Anchor;

  const [treeData, setTreeData] = useState();

  useEffect (() => {
   document.querySelector('html').classList.add('ios-clickable');

   async function getAppBlobSas() {
     try {
      const response = await fetch(`${process.env.REACT_APP_APP_BLOB_SAS_URL}`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 'blob_name': `${process.env.REACT_APP_APP_BLOB_NAME}`})
        }
      );

      const appBlobSas = await response.json();
      return appBlobSas.blob_sas;
     } catch (err) {
      console.error(err);
     }

   };

   async function getData(appBlobSas) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BLOB_DIRECTORY_TREE_URL}?${appBlobSas}`);
  
        const dirStructure = await response.json();
        return dirStructure;
      } catch (err) {
        console.error(err);
      }
   };

   getAppBlobSas().then(appBlobSas => getData(appBlobSas)).then(data => setTreeData(data));

  }, []);

  const [isModalVisible, setIsModalVisible] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
  <Layout style={{ backgroundColor: 'white' }}>
    <Header className="header"
            style={{ backgroundColor: 'white', position: 'fixed', zIndex: 1, width: '100%', height: '84px', paddingTop: '13px', paddingBottom: '13px' }}
    >
      <div>
        <a href="https://www.goalsolutions.com">
          <img src={ goal_logo } alt="Goal Structured Solutions" style={{ width: '173px', height: '58px'}} />
        </a>
      </div>
    </Header>
    <Layout style={{ height: '100vh', position: 'sticky'}}>
      <Sider
        theme="light"
        width="100%"
        style={{
          overflow: 'auto',
          height: '100vh',
          left: 0,
          top: '84px'
        }}
      > 
      <Card title="ABS INVESTOR REPORTING" bodyStyle={{ paddingBottom: '6px' }} > 
        <div style={{ fontWeight: 'bold'}}>By using this page, you agree to the Investor Relations&nbsp;&nbsp;<Button type="primary" onClick={showModal} style={{ fontWeight: 'bold' }}>Terms Of Use</Button></div>  
<div>
<br />
<p>Goal Structured Solutions provides Transaction Monitoring and Trust Administration services to securitization trusts that issue student loan asset backed securities. The asset-backed securities issued by the trusts do not represent interests in or obligations of, and are not guaranteed or insured by, Goal Structured Solutions or its affiliates.</p>

<p>Monthly Investor Reports for securitization trusts for which Goal Structured Solutions provides Trust Administration services or performs administration services as an agent for the Trust Administrator are available in the section below.</p>

<p>For those interested, existing or potential investors in the Goal Structured Solutions 2015-1 Class R Notes, there are significant transfer restrictions set forth in the Private Placement Memorandum under the heading &ldquo;Transfer Restrictions&rdquo;.&nbsp; Please click on the Private Placement Memorandum under the tab &ldquo;Goal Structured Solutions 2015-1&rdquo;.</p>
</div>
      </Card>

      <Modal title="Terms Of Use" 
        visible={isModalVisible} 
        onOk={handleOk} 
        closable={false}
        bodyStyle={{ height: '350px', overflowY: 'scroll' }}
        footer = {
          [
            <Button type="primary" key="accept" onClick={handleOk} style={{ fontWeight: 'bold' }}>
              Accept
            </Button>
          ]
        }
      >
        <div style={{ backgroundColor: '#15D1D1', color: 'white', fontWeight: 'bold', padding: '10px', borderWidth: '2px' }}>
          Welcome to Goal Structured Solutions, before you can view this content, you must read and agree to the Terms of Use.
        </div>
<div>
 <br />
 <p>Goal Structured Solutions or its affiliates provide Transaction Monitoring, Trust Administration or other administration services to securitization trusts that issue student loan asset backed securities. The asset-backed securities issued by the trusts do not represent interests in or obligations of, and are not guaranteed or insured by, Goal Structured Solutions or its affiliates.</p>

<p>ABS INVESTORS &ndash; Additional Disclosures</p>

<p>Investment considerations. Each issuer is a separate legal entity having responsibility for its own affairs. Goal Structured Solutions does not guarantee or otherwise stand behind the securities of those issuers. Investment in securities issued by any issuer entails certain risks described in the applicable offering documents. Certain offerings are suitable only for certain types of investors. Prospective investors should consult their own financial and legal advisors about risks associated with investment in a particular issue of securities and the suitability of investing in the securities in light of their particular circumstances.</p>

<p>No Assurance That Disclosures are Current. The documents made available on this web site are historical and relate to specific transactions at particular times. Circumstances and events may vary from transaction to transaction and may have changed materially since the dates of the various documents.</p>

<p>Forward Looking Statements. The Private Securities Litigation Reform Act of 1995 provides a safe harbor for forward-looking statements. The documents made available on this web site may contain certain statements of a forward-looking nature relating to future events or future business performance. Any such statements that refer to estimated or anticipated future results or other non-historical facts are forward-looking and reflect the current perspective of existing trends and information. These statements involve risks and uncertainties that cannot be predicted or quantified and, consequently, actual results may differ materially from those expressed or implied by such forward-looking statements. The forward-looking statements speak only as of the date of the documents in which they are made. Goal Structured Solutions undertakes no obligation to update publicly any forward-looking statement, whether as a result of new information, future events or otherwise.</p>

<p>No offer or solicitation. This Web site and the information contained in the Prospectuses, Offering Memorandums and related Supplements either on this site or linked to from this site do not constitute an offer or the solicitation of an offer for the purchase or sale of any securities. The Prospectuses, Offering Memorandums and related Supplements have been put on this Web site or linked to this Web site for informational purposes only. The securities described therein may be offered and sold only by means of a printed (but not downloaded and printed from this Web site) Prospectus or Offering Memorandum and related Supplements thereof and, as may be stated therein in particular instances, only to qualified institutional investors and certain other persons in accordance with applicable federal and state securities laws, by dealers authorized to make such offers and sales. By viewing a Prospectus, Offering Memorandum, or Supplement online, you consent to receive an electronic Prospectus, Offering Memorandum or Supplement for purposes of federal and state securities laws. The securities described in the Prospectuses, Offering Memorandum and Supplements may be traded in the secondary market. For further information concerning secondary market trading, consult with an investment advisor or broker.</p>

<p>No liability. The Prospectuses and Offering Memorandums have been electronically converted from the magnetic media format from which the original version of each such document was printed, and neither Goal Structured Solutions nor its subsidiaries or affiliates have tested or verified the accuracy or completeness of any of these documents on this Web site or any Web site to which this Web site is linked. Neither Goal Structured Solutions, any student loan trust for which Goal Structured Solutions provides Transaction Monitoring, Trust Administration or other services, nor underwriter assumes any responsibility for errors or omissions in any Prospectus, Offering Memorandum or other documents which are referenced by or linked thereto resulting from such electronic conversion. In no event shall Goal Structured Solutions, any student loan trust for which Goal Structured Solutions provides Transaction Monitoring, Trust Administration or other services, or underwriter be liable for any special, incidental, indirect or consequential damages of any kind, or any damages whatsoever, on any theory of liability arising out of or in connection with the use of any Prospectus or Offering Memorandum.</p>
</div>
      </Modal>
      <br />
      {treeData ? <TreeComponent hierarchy={ treeData } /> : <Spin />}
      <Anchor>
        <Link href="https://www.ascentfunding.com/investors" title="Ascent Education Funding Trust 2024-A" />
      </Anchor>
      </Sider>
    </Layout>
  </Layout>
  );
}

export default App;