import React from 'react'
import 'antd/dist/antd.css'
import '../index.css'
import { DownloadOutlined } from '@ant-design/icons'
import { Tree } from 'antd'
const { TreeNode } = Tree
const { DirectoryTree } = Tree


const areEqual = (prevProps, nextProps) => true;

const TreeComponent = ({ hierarchy }) => {

  async function getInvestorReportingBlobSas(blobName) {
    try {
     const response = await fetch(`${process.env.REACT_APP_INVESTOR_REPORTING_BLOB_SAS_URL}`, {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
         },
         body: JSON.stringify({ 'blob_name': `${blobName}`})
       }
     );

     const investorReportingBlobSas = await response.json();
     return investorReportingBlobSas.blob_sas;
    } catch (err) {
     console.error(err);
    }

  };

  const renderTreeNodes = (data) => 
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        )
      }

      let blob_link = (e) => {
        e.preventDefault();
        const investor_report = window.open('', '_blank');
        getInvestorReportingBlobSas(item.blob_name)
        .then(sas => {
          investor_report.location.href = `${item.url}?${sas}`
        }); 
      }


      return <TreeNode icon={<a id={ item.blob_name} href={ item.url } target="_blank" rel="noopener noreferrer" 
        onMouseUp={ blob_link } 
      ><DownloadOutlined style={{ color: '#15D1D1' }} /></a>}  key={item.key} title={item.title}  /> 

    })

  return (
    <DirectoryTree multiple>
      {renderTreeNodes(hierarchy)}
    </DirectoryTree>
  )
}

export default React.memo(TreeComponent, areEqual);